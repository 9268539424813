export default function shuffleArray(array: Array<any>) {
  // Loop in all elements
  for(let i = array.length - 1; i > 0; i--) {
    // Pick random element
    const j = Math.floor(Math.random() * (i + 1));
    // repositioning element
    [array[i], array[j]] = [array[j], array[i]];
  }
  // Return a random array
  return array;
}