import { doc, getDoc } from "firebase/firestore/lite";
import db from "../../database/firebase";
import { UserProfile } from "../../interfaces";

export async function getUserProfileById(userId: string): Promise<UserProfile> {
  return new Promise((resolve, reject) => {
    getDoc(doc(db, "userProfiles", userId)).then(
      (result) => {
        var data = result.data();

        if (data !== undefined) {
          var user: UserProfile = {
            _id: data.id,
            name: data.name,
            avatarUrl: data.avatarUrl,
          };

          return resolve(user);
        }
      },
      (error) => {
        return reject(error);
      }
    );
  });
}
