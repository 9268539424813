/* eslint-disable no-loop-func */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";

import AudioPlayer, { RHAP_UI } from 'react-h5-audio-player';
import { TiMediaPauseOutline, TiMediaPlayOutline } from 'react-icons/ti';
import { PlaylistModel, CompositionModel } from "../../core/interfaces";
import LoaderPage from "./components/Loader/LoaderPage";
import Spinner from './components/Loader/Spinner'
import { CompositionContainer, Compositions, Container, PlaylistStyled } from './styles'
import 'react-h5-audio-player/lib/styles.css';
import { getTimelineCarouselsPlaylistByType } from "../../core/repositories/playlist/getTimelineCarouselsPlaylistByType";
import { getCompositionsIdsByPlaylistSource } from "../../core/repositories/composition/getCompositionsIdsByPlaylistSource";
import { getCompositionById } from "../../core/repositories/composition/getCompositionById";
import { getUserProfileById } from "../../core/repositories/user/getUserProfileById";
import { getCompositionsIdsByPlaylistId } from "../../core/repositories/composition/getCompositionsIdsByPlaylistId";
import shuffleArray from "../../core/utils/shuffleArray";

export const Playlist = () => {
  const playerRef = useRef<HTMLAudioElement | any>(null);

  const [searchParams] = useSearchParams();
  const type = searchParams.get('type');

  const [loading, setLoading] = useState(true);

  
  const [playlist, setPlaylist] = useState<PlaylistModel>({} as PlaylistModel);
  const [compositions, setCompositions] = useState<Array<CompositionModel>>([]);

  const _handleType = async (type: string | null) => {
    switch (type) {
      case 'top_artists':
        await _handleTopArtistsPlaylistData(type); return;
      case 'highlights':
        await _handleHighlightsPlaylistData(type); return;
  
      default:
        var types: string[] = ['top_artists', 'highlights'];
        types = shuffleArray(types);
        _handleType(types[0]);
    }
  };

  const _handleHighlightsPlaylistData = async (type: string) => {
    const playlistResult = await getTimelineCarouselsPlaylistByType(type);

    setPlaylist(playlistResult);

    var compositionsIds = await getCompositionsIdsByPlaylistId(playlistResult.id!);
    
    const listCompositions: CompositionModel[] = [];
    await Promise.all(compositionsIds.map(async (id) => {

      var c = await getCompositionById(id);

      for (const songwritterId of c.songwritersIds) {
        var user = await getUserProfileById(songwritterId);
        if(user !== undefined) {
          c.songwritersListName.push(user.name);
        }
      }

      var name;
  
        if(c.songwritersListName.length > 2){
          name = `${c.songwritersListName[0]} & ${c.songwritersListName[1]}, mais ${c.songwritersListName.length - 2}`
  
          c.songwriterName = name;
  
        } else if(c.songwritersListName.length === 2){
          name = `${c.songwritersListName[0]} & ${c.songwritersListName[1]}`
  
          c.songwriterName = name;
        } else if(c.songwritersListName.length === 1){
          name = `${c.songwritersListName[0]}`
  
          c.songwriterName = name; 
        } 

        listCompositions.push(c);

    }));

    setCompositions(listCompositions);
    setLoading(false);
    
  }
  

  const _handleTopArtistsPlaylistData = async (type: string) => {
    const playlistResult = await getTimelineCarouselsPlaylistByType(type);
    setPlaylist(playlistResult);


    var compositionsIds = await getCompositionsIdsByPlaylistSource(playlistResult.source!, 3);
    
    const listCompositions: CompositionModel[] = [];
    await Promise.all(compositionsIds.map(async (id) => {
      var c = await getCompositionById(id);

      for (const songwritterId of c.songwritersIds) {
        var user = await getUserProfileById(songwritterId);
        if(user !== undefined) {
          c.songwritersListName.push(user.name);
        }
      }

      var name;
  
      if(c.songwritersListName.length > 2){
        name = `${c.songwritersListName[0]} & ${c.songwritersListName[1]}, mais ${c.songwritersListName.length - 2}`

        c.songwriterName = name;

      } else if(c.songwritersListName.length === 2){
        name = `${c.songwritersListName[0]} & ${c.songwritersListName[1]}`

        c.songwriterName = name;
      } else if(c.songwritersListName.length === 1){
        name = `${c.songwritersListName[0]}`

        c.songwriterName = name;
      }

      listCompositions.push(c);
    }));

    setCompositions(listCompositions);
    setLoading(false);

  }

  useEffect(() => { 
    (async () => {
      await _handleType(type)
    })();
  }, []);

 

  if(loading) {
    return (
      <LoaderPage>
        <Spinner loading  />
      </LoaderPage>
    );
  }

  return (
    <>
      <Container>  
      <PlaylistStyled >
            <img src={playlist.coverUrls !== undefined ? playlist.coverUrls[0] : playlist.coverUrl} alt="Nome da playlist" />
            <h4>
              {playlist.title.pt}
            </h4>
      </PlaylistStyled>
      
       <CompositionContainer>
          {
                    compositions.map((composition) => (
                      <Compositions>
                        <div className="artist" key={composition.id}>
                          <img src={composition.coverUrl} alt="Artist"/>
                          <div className="songWrittersContainer">
                            <p className="title">{composition.title}</p>
                            <p className="songWritters">{composition.songwriterName}</p>
                          </div>
                        </div>
                       <div className="player--box">
                        <AudioPlayer 
                            src={composition.audioUrl}
                            customIcons={{
                              play: <TiMediaPlayOutline size={24} />,
                              pause: <TiMediaPauseOutline size={24} />
                            }}
                            customProgressBarSection={[
                              RHAP_UI.CURRENT_TIME,
                              <span style={{ color: '#8F8F8F', fontSize: 12, margin: '0 5px' }}> / </span>,
                              RHAP_UI.DURATION,
                              RHAP_UI.PROGRESS_BAR
                            ]}
                            customVolumeControls={[]}
                            customAdditionalControls={[]}
                            layout="horizontal"
                            showJumpControls={false}
                            autoPlay={false}
                            ref={playerRef}
                          />
                       </div>
                       <div className="button">
                         <a href={`https://cqc.musicplayce.com/${composition.id}`} target='_blank' rel="noopener noreferrer">
                            <button className="indication--button" type="button">
                              Indicar
                            </button>
                         </a>
                       </div>
                    </Compositions>
                  )
                )
  }
        </CompositionContainer>
      </Container>
    </>
  )
}   