/* eslint-disable no-loop-func */
import { doc, getDoc } from "firebase/firestore/lite";
import db from "../../database/firebase";
import { CompositionModel } from "../../interfaces";
import defaultCoverUrl from "../../utils/defaultCoverUrl";

export async function getCompositionById(
  id: string
): Promise<CompositionModel> {
  return new Promise((resolve, reject) => {
    var composition: CompositionModel = {
      id: "",
      audioUrl: "",
      coverUrl: "",
      songwritersIds: [],
      songwritersListName: [],
      songwriterName: "",
      title: "",
    };

    getDoc(doc(db, "compositions", id)).then(
      (result) => {
        composition.id = result.id;
        composition.coverUrl = result.data()!.coverUrl ?? defaultCoverUrl();
        composition.songwritersIds = result.data()!.songwritersIds;
        composition.title = result.data()!.title;
        composition.audioUrl = result.data()!.audioUrl;
        composition.songwritersListName = [];
        return resolve(composition);
      },
      (error) => {
        return reject(error);
      }
    );
  });
}
