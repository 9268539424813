import styled from "styled-components";

export const Container = styled.div`
  height: 100%;
  width: 100%;

  padding-top: 4rem;

  h4,
  h1 {
    color: #fff;
  }
`;

export const PlaylistStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h4 {
    padding-top: 1rem;

    max-width: 500px;
    text-align: center;
    font: 600 2rem "Inter", sans-serif;
    padding: 2rem;
  }

  img {
    height: 200px;
    width: 200px;
    box-shadow: 0 0 0.7em black;
  }
`;

export const CompositionContainer = styled.div`
  display: flex;
  flex-direction: row;

  padding-top: 3rem;

  @media (max-width: 962px) {
    flex-direction: column;
  }
`;

export const Compositions = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;

  @media (max-width: 962px) {
    padding-bottom: 4rem;
  }

  .button {
    padding-left: 2rem;
    display: flex;
    justify-content: center;
  }

  button.indication--button {
    padding: 6px 30px;
    color: #fff;
    background-color: #0d65ff;
    font-size: 1rem;
    border-radius: 30px;
    border: 0;

    -webkit-appearance: button;
    -moz-appearance: button;
    appearance: button;

    text-decoration: none;
  }

  .title {
    color: #cfcfcf;
    font-size: 0.9rem;
    font-weight: bold;

    padding: 0.5rem 0;

    text-align: center;
  }

  .songWritters {
    width: 100%;
    max-width: 170px;

    color: #504d4d;
    font: 400 0.7rem "Inter", sans-serif;

    text-align: center;
  }

  .songWrittersContainer {
    display: flex;

    flex-direction: column;
    align-items: center;
  }

  .artist {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  div img {
    margin: 0 1rem;

    height: 60px;
    width: 60px;

    border: 5px #333 solid;
    border-radius: 50px;
  }

  .player--box {
    width: 100%;
    max-width: 400px;

    margin-left: 2rem;

    div.rhap_time {
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      color: #8f8f8f;
    }

    div.rhap_container {
      border-radius: 8px;
      box-shadow: none;
      background-color: #202020;

      div.rhap_progress-section {
        div.rhap_progress-container {
          div.rhap_progress-indicator {
            visibility: hidden;
          }

          div.rhap_download-progress {
            background: #383737;
          }

          div.rhap_progress-filled {
            background: linear-gradient(
              270.01deg,
              #0d65ff 0.01%,
              rgba(13, 94, 255, 0) 100%
            );
            box-shadow: 0px 0px 10px #0d65ff;
          }
        }
      }

      div.rhap_controls-section {
        flex: 0;
        margin: 0;

        div.rhap_main-controls {
          button {
            margin: 0;
            width: 24px;
            display: flex;
            align-items: center;
          }
        }
      }

      svg {
        margin: none !important;
        margin-right: none !important;
        path {
          fill: #fff;
        }
      }
    }
  }
`;
