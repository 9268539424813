import {
  collection,
  getDocs,
  limit,
  orderBy,
  query,
} from "firebase/firestore/lite";
import db from "../../database/firebase";

export async function getCompositionsIdsByPlaylistSource(
  playlistSource: string,
  docLimit: number
): Promise<string[]> {
  return new Promise((resolve, reject) => {
    var compositionsIds: string[] = [];

    const indicationsToRef = query(
      collection(db, `userProfiles/${playlistSource}/indicationsTo`),
      orderBy("indicationsCount"),
      limit(docLimit)
    );

    getDocs(indicationsToRef).then(
      (result) => {
        for (const indicationDoc of result.docs) {
          compositionsIds.push(indicationDoc.data().compositionId);
        }

        return resolve(compositionsIds);
      },
      (error) => {
        return reject(error);
      }
    );
  });
}
