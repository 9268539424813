import { doc, getDoc } from "firebase/firestore/lite";
import db from "../../database/firebase";

export async function getCompositionsIdsByPlaylistId(
  playlistId: string
): Promise<string[]> {
  return new Promise((resolve, reject) => {
    var ids: string[] = [];

    getDoc(doc(db, "playlists", playlistId)).then(
      (result) => {
        var tmpIds: string[] = result.data()!.compositionsIds;

        if (tmpIds.length > 3) {
          ids = [tmpIds[0], tmpIds[1], tmpIds[2]];
        } else {
          ids = tmpIds;
        }

        return resolve(ids);
      },
      (error) => {
        return reject(error);
      }
    );
  });
}
