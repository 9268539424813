import { doc, getDoc } from "firebase/firestore/lite";
import db from "../../database/firebase";
import { PlaylistModel } from "../../interfaces";
import shuffleArray from "../../utils/shuffleArray";

export async function getTimelineCarouselsPlaylistByType(
  type: string
): Promise<PlaylistModel> {
  return new Promise((resolve, reject) => {
    var playlists: PlaylistModel[] = [];

    getDoc(doc(db, "timelineCarousels", type)).then(
      (result) => {
        if (result.data() !== undefined) {
          result.data()!.playlists.forEach((p: PlaylistModel) => {
            playlists.push(p);
          });

          playlists = shuffleArray(playlists);

          return resolve(playlists[0]);
        }
      },
      (error) => {
        return reject(error);
      }
    );
  });
}
