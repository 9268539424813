import { BrowserRouter, Route, Routes} from 'react-router-dom';

import { Playlist } from '../pages/Playlist';

export const Router = () => {
  return (
    <BrowserRouter>
      <Routes >
        <Route path='/' element={<Playlist />}/>
      </Routes >
    </BrowserRouter>
  )
}