import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore/lite";

const prodConfig = {
  apiKey: "AIzaSyCpTP-_eZjRthBwNPjxZE7o8m07w4qD6tg",
  authDomain: "musicplayce-prod.firebaseapp.com",
  projectId: "musicplayce-prod",
  storageBucket: "musicplayce-prod.appspot.com",
  messagingSenderId: "581502747976",
  appId: "1:581502747976:web:ef8af61c6fe173cf5b1f7d",
  measurementId: "G-SHGM4VXNH3",
};

const app = initializeApp(prodConfig);
const db = getFirestore(app);

export default db;
