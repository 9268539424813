import React from 'react';
import { Router } from './core/Router';
import GlobalStyle from './core/styles/globalStyles';

function App() {
  return (
    <>
      <GlobalStyle /> 
      <Router />

    {/* <div className='container'>
     <div className='box' aria-disabled="true">
       
     <textarea onClick={handleClick} rows={4} cols={50} readOnly>
          {
            "<iframe width='560' height='315' src='http://localhost:3000/' title='Testing iframe' />"
          }
      </textarea>
     </div>
    </div> */}
    </>   
  );
}

export default App;
