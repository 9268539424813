import styled from "styled-components";

const InfoAuditionSection = styled.section`
  display: flex;
  margin: 0 auto;
  width: 100vw;
  height: 100vh;
  padding: 0px 30px;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background-color: #202020;
`;

export default InfoAuditionSection;
